import React, { useState, useEffect, useRef } from "react";
import { NavLink, useNavigate, Link, useLocation } from 'react-router-dom';
import UrbanHeader from "../../Components/urbanHeader/UrbanHeader";
import NewFooter from "../../Components/NewFooter";
import classes from "./mdas.module.css";
import NewFooter2 from "../../Components/NewFooter2";
import NewsIcon from '../../assets/olumo.png'
import HeaderNav2 from "../../Components/HeaderNav/HeaderNav2";
import IMGFile from '../../assets/urban/logs.png'
import Comm1 from '../../assets/urban/oga.png'
import Pic1 from '../../assets/urban/pic1.png'
import Pic2 from '../../assets/urban/pic2.png'
import Pic3 from '../../assets/urban/pic3.png'
import Pic4 from '../../assets/urban/pic4.png'
import Pic5 from '../../assets/urban/pic5.png'
import Pic6 from '../../assets/urban/pic6.png'
import axios from "axios";
import Icon1 from '../../assets/executives-icon/icon.png';
import Icon11 from '../../assets/landingpage/ic1.png'
import Icon2 from '../../assets/landingpage/ic2.png'
import Icon3 from '../../assets/landingpage/ic3.png'
import { Spinner, Button, Accordion } from "react-bootstrap";
import { BASE_URL } from "../../BaseUrl/BaseUrl";
import logo from '../../assets/og logo.svg';
import logo1 from '../../assets/New1.png';
import Mail from '../../assets/mail.png';
import Call from '../../assets/call.png';
import Location from '../../assets/location.png';
import HalfMap from '../../assets/halfMap.svg';
import footerLogo from "../../assets/Footer-logo.svg";
import { FaRegCalendarAlt } from "react-icons/fa";
import { WiTime8 } from "react-icons/wi";

function MinistryUrban() {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    
    const location = useLocation();
    const { selectedMda } = location.state || {};
    console.log(selectedMda, "DETAILS");
    const [documents, setDocuments] = useState([]);
    const [clickedMenu, setClickedMenu] = useState('');
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalEntries, setTotalEntries] = useState("");
    const [totalPages, setTotalPages] = useState("");

    const aboutUsRef = useRef(null);
    const newsRef = useRef(null);
    const homeRef = useRef(null);
    const faqsRef = useRef(null);
    const contactRef = useRef(null);

    let pictures = [];
    let videoss = [];
    let news = [];
    let faqs = [];
    let services = [];

if (selectedMda && selectedMda.media) {
    selectedMda.media.forEach(mediaItem => {
        if (mediaItem.media_type === "1") {
            pictures.push(mediaItem);
        } else if (mediaItem.media_type === "2") {
            videoss.push(mediaItem);
        }
    });

    if (selectedMda.news) {
      news = selectedMda.news;
  }

  if (selectedMda.faqs) {
      faqs = selectedMda.faqs; 
  }
  if (selectedMda.services) {
      services = selectedMda.services; 
  }
}



    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    const handleNavigateToHome = () => {
        navigate('/')
      }


  

    function formatDate(dateString) {
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

        const date = new Date(dateString);
        const dayOfWeek = days[date.getDay()];
        const month = months[date.getMonth()];
        const dayOfMonth = date.getDate();
        const year = date.getFullYear();
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12;
        const formattedDate = `${dayOfWeek}, ${month}, ${dayOfMonth} ${year} at ${hours}:${minutes.toString().padStart(2, '0')} ${ampm}`;
        return formattedDate;
    }

    const handleReadMore = (item) => {
        navigate('/read_news_mdas', { state: { selectedNews: item } });
    }

    const photos = [
        { url: Pic1, description: 'Description of Photo here', subtext: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco' },
        { url: Pic2, description: 'Description of Photo here', subtext: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco' },
        { url: Pic3, description: 'Description of Photo here', subtext: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco' },
        { url: Pic4, description: 'Description of Photo here', subtext: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco' },
        { url: Pic5, description: 'Description of Photo here', subtext: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco' },
        { url: Pic6, description: 'Description of Photo here', subtext: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco' }
    ];

    // const videos = [
    //     { url: 'https://www.youtube.com/embed/pCC0PujV7wY', description: 'Construction of Housing estate' },
    //     { url: 'https://www.youtube.com/embed/crUUiQaOm5I', description: 'Construction of Housing estate' },
    //     { url: 'https://www.youtube.com/embed/XW3W6Te4b2o', description: 'Construction of Housing estate' },
    //     { url: 'https://www.youtube.com/embed/bZaq3OP95ME', description: 'Construction of Housing estate' },
    // ];

    const handleGoBack = () => {
        navigate(-1);
    }

    const scrollToSection = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
    }

    const getEmbedLink = (link) => {
      const fileId = link.match(/[-\w]{25,}/);
      return fileId ? `https://drive.google.com/file/d/${fileId[0]}/preview` : link;
    };

    const firstVideoUrl = videoss.length > 0 ? videoss[0].file_path : '';

    const maxLength = 1500;
    const truncatedText = selectedMda?.commisioners_profile?.length > maxLength ? selectedMda?.commisioners_profile?.substring(0, maxLength) + '...' : selectedMda?.commisioners_profile;

    const getOrdinalSuffix = (number) => {
      const suffixes = ['th', 'st', 'nd', 'rd'];
      const v = number % 100;
      return v >= 11 && v <= 13
          ? 'th'
          : suffixes[v % 10] || 'th';
  };

  const formattDate = (date) => {
      const day = date.getDate();
      const month = date.toLocaleString('default', { month: 'long' });
      const year = date.getFullYear();

      return `${day}${getOrdinalSuffix(day)} ${month}, ${year}`;
  };

    return (
        <div>
            {/* <HeaderNav2 onMenuClick={handleMenuClick} /> */}
            <div className={classes.headerSection} >
      <nav expand="lg" className={classes.nav}>
        <div className={classes.navContainer} >
          <NavLink to={'/'} className={classes.navLogoWeb}><img src={logo}  className={classes.logoicon}/></NavLink>
          <ul className={`${classes.mainMenu} ${isMenuOpen ?  '' : classes.menuOpen}`}>
            <li className={classes.linkList}>
              <li  to={'#'} onClick={() => scrollToSection(homeRef)}>Home</li>
              <li to={'#'} onClick={() => scrollToSection(aboutUsRef)}>About us</li>
              <li to={'#'} onClick={() => scrollToSection(newsRef)}>News</li>
              <li to={'#'} onClick={() => scrollToSection(faqsRef)}>FAQs</li>
              <li to={'#'} onClick={() => scrollToSection(contactRef)}>Contact Us</li>
            </li>
            <li className={classes.navItem}>
            </li>
            <button onClick={handleGoBack} className={classes.mdaButton}>← Ogun state MDAs</button>
          </ul>
          <div className={classes.harmborgers} onClick={toggleMenu}>
            {isMenuOpen ? (
              <span className={classes.closs}>
                <i className='bx bx-x'></i>

              </span>
            ) : (
              <span className={classes.open}>
                <i className='bx bx-menu'></i>
              </span>
            )}
          </div>
        </div>
      </nav>
    </div>
            <div className={classes.generalBody}>

                <div className={classes.wrapper}>
                    <section ref={homeRef} className={classes.wrapperBackground}>

                        <h1>{selectedMda?.name}</h1>
                        <p className={classes.subHeaderText}>{selectedMda.about_mda}
                        </p>

                        <div className={classes.videoSection}>
                        {firstVideoUrl ? (
            <iframe
                width="900"
                height="600"
                src={getEmbedLink(firstVideoUrl)}
                title="Our videos"
                frameBorder="1"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{ border: "3px solid #164B2E" }}
            ></iframe>
        ) : (
            <p className={classes.noDetails}>No video available</p>
        )}
                        </div>
                    </section>
                </div>


                <div className={classes.headkad}>
                    <div className={classes.firstBox}>
                        <h1>OUR VISION</h1>
                        <p>{selectedMda.vision}</p>
                    </div>
                    <div className={classes.secondBox}>
                        <h1>OUR MISSION</h1>
                        <p>{selectedMda.mission}</p>
                    </div>
                </div>

                <section ref={aboutUsRef} className={classes.aboutSection}>
                    <div className={classes.aboutText}>
                        <h1>About us</h1>
                        <p>{selectedMda.name}</p>
                    </div>
                </section>
                <div className={classes.aboutHeader}>
                    <div className={classes.aboutPicture}>
                        <img src={IMGFile} style={{ objectFit: "cover", height: 300, width: 500 }} alt="image" />
                    </div>
                    <div className={classes.aboutPicText}>
                        <p>{selectedMda.about_mda === null ? selectedMda.name : selectedMda.about_mda}</p>
                       
                    </div>
                </div>

                <div className={classes.aboutSection}>
                    <div className={classes.aboutText}>
                        <h1>OUR SERVICES</h1>
                        {/* <p>Your One Stop Shop For Your Approvals/Permit</p> */}
                    </div>
                </div>
                <div className={`${classes.wrapper} ${classes.threeCardsbg}`}>
            <div className={`${classes.container} ${classes.threeCards}`}>
                {services.length > 0 ? (
                    services.map((item, index) => (
                        <div key={index} className={`${classes.cards} ${classes.card4}`}>
                            <div className={classes.cardItems}>
                                <img src={Icon11} className={classes.eclipse} alt={item.service_title} />
                                <h2 className={classes.cardText}>{item.service_title}</h2>
                                <p>{item.description}</p>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className={classes.noServices}>No services available</div>
                )}
            </div>
        </div>


                <div className={classes.bioSection}>
                    <div className={classes.bioText}>
                        <h1>Meet the {selectedMda.mda_type === "1" ? "Commissioner" : selectedMda.mda_type === "2" ? "Director" : selectedMda.mda_type === "3" ? "Head" : ""}
</h1>
                        <p>{selectedMda.name}</p>
                    </div>
                    <div className={classes.bioContainer}>
                        <div className={classes.bioUpdate}>
                            <p>{truncatedText}</p>
                            {/* <p>He coordinated the presentation/exhibition by Lagos State Government as part of the Nigerian Government Representation/Delegation to the Africities 4 Summit and Exhibition in Nairobi Kenya in 2006. He was the representative of Lagos State Government at the Un-Habitat First Stakeholders Meeting on Business Partnership for Sustainable Urbanisation / Habitat Business Expo and the 21st General Council Meeting of the Un-Habitat in Nairobi Kenya in 2007. In 2008, he represented Lagos State Government at the Cities Alliance/City of Sao Paolo Stakeholders Conference on Slum Upgrade in Sao Paolo Brazil. He also represented Lagos State and coordinated Lagos State exhibition/presentation at the 5th World Urban Forum in Rio de Janeiro Brazil in 2010. He supervised the redevelopment of the old Broad Street Prison Lagos Nigeria, into ‘Lagos Freedom Park’ a public park from August 2009-October 2010. He also supervised the redevelopment of Tejuosho Urban Market Yaba Lagos Nigeria between April 2009 and December 2012. </p> */}
                        </div>
                        <div className={classes.bioCont1}>
                            <img src={selectedMda?.commisioners_image} style={{ objectFit: "cover", height: 400, width: 500 }} alt={selectedMda?.commisioners_name} />
                            <h1>{selectedMda?.commisioners_name}</h1>
                            <p>{selectedMda.mda_type === "1" ? "COMMISSIONER" : selectedMda.mda_type === "2" ? "DIRECTOR" : selectedMda.mda_type === "3" ? "HEAD" : ""}
, {selectedMda?.name}</p>
                        </div>
                    </div>

                </div>
                <div className={classes.photoSection}>
                    <div className={classes.photoContainer}>
                        <div className={classes.photoSect}>
                            <div className={classes.photoLine} />
                            <p>PHOTO GALLERY</p>
                            <div className={classes.photoLine} />
                        </div>

                        <div className={classes.photoGroup}>
                        { pictures.length === 0 ? (
                <p className={classes.recent}>No photo to display</p>
              ) : (
                            pictures.map((photo, index) => (
                                <div key={index} className={classes.photoItem}>
                                    <img src={photo.file_path} alt={photo.title} />
                                    <div style={{display: "flex", gap: 5, alignItems: "center", marginTop: 10}}>
                                    <WiTime8 style={{color: "#6C757D"}}/>
                                    <div className={classes.photoDescription1}>{formatDate(photo.created_at)}</div>
                                    </div>
                                    <div className={classes.photoDescription}>{photo.title}</div>
                                    <div className={classes.photoSubtext}>{photo.description}</div>
                                </div>
                              ))
                            )}
                        </div>
                    </div>
                </div>

                {pictures.length === 0 ? null : (
                <div className={classes.bottom}>

                    <p className={classes.bottomButton}>View more</p>
                </div>
                )}

                <div className={classes.bioSection}>
                    <div className={classes.videoText}>
                        <h1>Watch our Projects in Video</h1>
                    </div>

                    <div className={classes.videoContainer}>
                    { videoss.length === 0 ? (
                      <div className={classes.noVid}>
                <p className={classes.recent12}>No video to display</p>
                </div>
              ) : (
                        videoss.map((video, index) => (
                            <div key={index} className={classes.videoItem}>
                                <iframe
                                    src={getEmbedLink(video.file_path)}
                                    title={`Video ${index + 1}`}
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                                 <div style={{display: "flex", gap: 5, alignItems: "center", }}>
                                    <WiTime8 style={{color: "#fff"}}/>
                                    <div className={classes.photoDescription12}>{formatDate(video.created_at)}</div>
                                    </div>
                                <div className={classes.videoDescription}>{video.title}</div>
                            </div>
                        ))
                      )}
                    </div>

                    {videoss.length === 0 ? null : (
                    <div className={classes.videoBottom}>
                        <h1>View more</h1>
                    </div>
                    )}
                </div>








                




               







                <section ref={newsRef} className={classes.newsSection}>
          <h3>NEWS</h3>
          <div className={classes.newsGroup}>
            <div className={classes.newsContainer}>
              <img
                className="d-block w-100"
                src={NewsIcon}
                alt="News"
                style={{ objectFit: 'cover', height: 420, borderRadius: 8, padding: 10 }}
              />
              <p>Catch up with news and events happening in and around Ogun State. </p>
            </div>

            <div className={classes.newsData1}>
              
            { news.length === 0 ? (
                <p className={classes.recent}>No recent news to display</p>
              ) : (
                news?.slice(0, 6).map((item, index) => (
                  <div key={index} className={classes.newsData2}>
                    <h1 onClick={() => handleReadMore(item)}>{item?.title}</h1>
                    <p>{item?.short_desc}</p>
                    <div className={classes.newsBelow}>
                      <p>Posted on {formatDate(item.created_date)}</p>
                      <h1 onClick={() => handleReadMore(item)}>Read more...</h1>
                    </div>
                  </div>
                ))
              )}




            </div>
          </div>

          {news.length === 0 ? null : (
    <Button onClick={() => navigate('/news_mdas', { state: { news } })} variant="success">Read more</Button>
)}
        </section>

        <div ref={contactRef} className={classes.bioSection}>
<div className={classes.contactContainer}>
  <div className={classes. contact1}>
    <h1>Contact Us</h1>
    <p>Want to reach us? Contact us through <br /> the following channels</p>
  </div>
  <div className={classes. contact123}>
  <img
                className="d-block w-100"
                src={logo1}
                alt="og"
                style={{ objectFit: 'contain', height: 200, }}
              />
    {/* <p>Want to reach us? Contact us through <br /> the following channels</p> */}
  </div>
  <div className={classes. contact12}>
  <img
                className="d-block w-100"
                src={Mail}
                alt="og"
                style={{ objectFit: 'contain', height: 30, }}
              />
    <p>{selectedMda.email}</p>
  <img
                className="d-block w-100"
                src={Call}
                alt="og"
                style={{ objectFit: 'contain', height: 30, }}
              />
    <p>{selectedMda.phone} {selectedMda.phone_2}</p>
  <img
                className="d-block w-100"
                src={Location}
                alt="og"
                style={{ objectFit: 'contain', height: 30, }}
              />
    <p>{selectedMda.office_address}</p>
  <img
                className="d-block w-100"
                src={Location}
                alt="og"
                style={{ objectFit: 'contain', height: 30, }}
              />
    <p>{selectedMda.address}</p>
  </div>
</div>
</div>

        <p ref={faqsRef} className={classes.xplore}>FAQs</p>
                <p className={classes.xploretxt}>See answers to some frequently asked questions here</p>
                <div className={classes.accCont}>
        <div className={classes.accordionContainer}>
        <Accordion>
    {faqs.length === 0 ? (
        <p className={classes.recent}>No faqs to display</p>
    ) : (
        faqs.map((faq, index) => (
            <Accordion.Item className={classes.accordionSpace} eventKey={index.toString()} key={index}>
                <Accordion.Header>{faq.question}</Accordion.Header>
                <Accordion.Body>{faq.answer}</Accordion.Body>
            </Accordion.Item>
        ))
    )}
</Accordion>

        </div>
        </div>

            </div>
            <div className={`${classes.wrapper} ${classes.FooterBg2}`}>
          <div className={classes.containers}>
            <img src={HalfMap} alt="Ogun Map" className={classes.halfmap} />
            <div className={`${classes.container} ${classes.mainFooter}`}>
              {/* <div className={classes.finalDiv}> */}
              <div onClick={handleNavigateToHome} className={classes.footerLogoCont}>
                <div className={classes.footerLogo}>
                  <img src={footerLogo} alt="Ogun Logo" />
                </div>
                <p style={{ color: 'white' }} >
                It is peopled predominantly by the Egbas, Ijebus, Yewas, Remos and Aworis; - who belong to the main Yoruba ethnic group.  It also has sub-groups, namely, Ketu, Ikale, Ilaje, Ohori, Anago and Egun. There are also significant numbers of Nigerians from other parts of the country as well as foreign nationals resident in the State.

                  Ogun State welcomes you!
                </p>
              </div>

              <div className={`${classes.footerConts} ${classes.footerContsnav}`}>
                <h5 className={classes.name}>Link</h5>
                <div className={classes.footList}>
                <li to={'#'} onClick={() => scrollToSection(homeRef)}>Home</li>
                <li to={'#'} onClick={() => scrollToSection(aboutUsRef)}>About Us</li>
                                <li to={'#'} onClick={() => scrollToSection(newsRef)}>News</li>
                                <li to={'#'} onClick={() => scrollToSection(faqsRef)}>FAQs</li>
                                <li to={'#'} onClick={() => scrollToSection(contactRef)}>Contact Us</li>
                  {/* <NavLink to={'/'} ><p>Home</p></NavLink> */}
                  {/* <NavLink to={'/'} ><p>Event</p></NavLink>
              <NavLink to={'/'} ><p>Blog</p></NavLink>
              <NavLink to={'/'} ><p>News</p></NavLink> */}
                  {/* <NavLink to={'/ogstep_documents'} ><p>Contracts & Adverts</p></NavLink> */}
                </div>
              </div>

              {/* <div className={classes.footerConts}>
              <h5 className={classes.name}>MDAs</h5>
              <div>
                <p>Bureau of Public service reforms</p>
                <p>Ministry of Education, Science and Technology</p>
                <p>Ministry of Budget and Planning</p>
                <p>Ministry of Political Affairs and Administration</p>
              </div>
            </div> */}
              <div className={classes.footerConts}>
                <h5 className={classes.name}>Contact Us</h5>
                <div>
                  <p>Government House complex, Oke-Mosan, Abeokuta, Ogun State</p>
                  <p>info@ogunstate.gov.ng</p>
                </div>
              </div>


            </div>
          </div>
        </div>
        <div className={classes.footerText}>
          <p className={classes.subFooter}>© OGUN STATE GOVERNMENT </p>
          <p className={classes.subFooter1}>All Rights Reserved. Terms of Use and Privacy Policy</p>
        </div>
        </div >
    )
}
export default MinistryUrban;